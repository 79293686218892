<template>
    <div class="root-wrap">
        <div class="header-wrap">
            <van-count-down :time="orderInfo.remainTime" :auto-start="false" @finish="payTimeout" ref="countDown">
                <template #default="timeData">
                    <div class="count-down">
                        <span>剩余支付时间：</span>
                        <span class="block">{{ timeData.minutes > 9 ? timeData.minutes : '0' + timeData.minutes }}</span>
                        <span class="colon">:</span>
                        <span class="block">{{ timeData.seconds > 9 ? timeData.seconds : '0' + timeData.seconds }}</span>
                    </div>
                </template>
            </van-count-down>
        </div>
        <div class="body-wrap">
            <div class="food-wrap">
                <div class="food-item" v-for="item, index of list" :key="index">
                    <div class="food-info fx-row">
                        <div style="margin-right: 10px;">
                            <van-image :src="item.logo" width="2.8rem" height="2.5rem" radius="4px"></van-image>
                        </div>
                        <div class="fx-column">
                            <div class="food-name">{{item.name}}</div>
                            <div class="food-desc">{{item.itemDesc}}</div>
                            <div class="food-price">
                                <div class="fx-column" style="row-gap: 5px;">
                                    <div class="food-sold">已售0</div>
                                    <div>
                                        <span class="food-price_symbol">￥</span>
                                        <span class="food-price_text">{{item.price}}</span>
                                    </div>
                                </div>
                                <div class="fx-end">
                                    <van-stepper v-model="list[index].cartNum" theme="round" button-size="25" disable-input :show-minus="list[index].cartNum > 0" :default-value="0" min="0" :class="list[index].cartNum > 0 ? '' : 'empty'" @change="changecartNum" @plus="onPlus" />
                                </div>
                                <!-- <div class="food-sold">已售0</div>
                                <div>
                                    <span class="food-price_symbol">￥</span>
                                    <span class="food-price_text">{{item.price}}</span>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cart-wrap">
            <div class="cart">
                <div class="cart-info fx-fill" @click="openCartList">
                    <van-badge v-show="cartNumTotal > 0" :content="cartNumTotal">
                        <van-icon name="shopping-cart-o" color="#f5b225" size="25" />
                    </van-badge>
                    <van-icon v-show="cartNumTotal < 1" name="shopping-cart-o" color="#f5b225" size="25" />
                    <div v-if="cartAmount > 0" class="cart-price">
                        <span class="cart-price_symbol">￥</span>
                        <span class="cart-price_text">{{ cartAmount }}</span>
                    </div>
                    <div v-else style="margin-left: 15px;">提前买小食，美味不用等</div>
                </div>
                <div class="cart-confirm" @click="next">下一步</div>
            </div>
        </div>
        <van-popup v-model="showPopover" position="bottom" :style="{height: '50%', zIndex: '2023'}" round duration="0.1" :overlay-style="{opacity: 0.5}">
            <div class="cart-header">
                <van-icon name="delete-o" size="20" @click="clear" /><span>清空</span>
            </div>
            <div class="cart-body">
                <div v-for="item, index of cartList" :key="index" class="fx-row fx-between">
                    <div class="fx-column cart-item_info">
                        <div class="cart-item_title">{{item.name}}</div>
                        <div>
                            <span class="cart-item_symbol">￥</span>
                            <span class="cart-item_text">{{ item.price * item.cartNum }}</span>
                        </div>
                    </div>
                    <div class="fx-column fx-middle">
                        <van-stepper v-model="cartList[index].cartNum" theme="round" button-size="25" disable-input :show-minus="cartList[index].cartNum > 0" :default-value="0" min="0" :class="cartList[index].cartNum > 0 ? '' : 'empty'" />
                    </div>
                </div>
            </div>
        </van-popup>

    </div>
</template>

<script>
//提前买小食,美味不用等
import { getOrder, addOrderFood } from '@api/order-request';
import { getCinemaFood } from '@api/cinema-request';
export default {
    data() {
        return {
            orderId: this.$route.query.orderId,
            cinemaId: this.$route.query.cinemaId,
            orderInfo: {},
            list: [],
            showPopover: false,
        }
    },
    computed: {
        cartNumTotal() {
            return this.list.reduce((total, item) => {
                return total + item.cartNum
            }, 0)
        },
        cartAmount() {
            return this.list.reduce((total, item) => {
                return total + item.cartNum * item.price
            }, 0)
        },
        cartList: {
            get() {
                return this.list.filter((item) => {
                    return item.cartNum > 0;
                });
            },
        }
    },
    created() {
        this.getCinemaFood();
        this.getOrderInfo();
    },
    watch: {
        cartList() {
            if (this.cartList.length < 1) {
                this.showPopover = false;
            }
        }
    },
    methods: {
        next() {
            let foodArray = [];
            for (let item of this.cartList) {
                for (let i = 0; i < item.cartNum; i++) {
                    foodArray.push(item.id)
                }
            }
            if (foodArray.length > 0) {
                let params = {
                    orderId: this.orderId,
                    bmhIds: foodArray.join(',')
                }
                addOrderFood(params).then((res) => {
                    this.$goPage('order-pay', {
                        orderId: this.orderId
                    }, true);
                }).catch(({ errorCode, errorMsg }) => {
                    if (errorMsg) {
                        this.$toast(errorMsg);
                    }
                })
            } else {
                this.$goPage('order-pay', {
                    orderId: this.orderId
                }, true);
            }

        },
        getOrderInfo() {
            getOrder(this.orderId).then((res) => {
                let order = res;
                this.orderInfo = order;
                this.$nextTick(() => {
                    this.$refs.countDown.start();
                })
            }).catch((error) => {
            });
        },
        getCinemaFood() {
            getCinemaFood(this.cinemaId).then((res) => {
                this.list = res;
            })
        },
        clear() {
            this.list = this.list.map((item) => {
                return {
                    ...item,
                    cartNum: 0
                }
            })
        },
        payTimeout() {
            //购买超时
        },
        changecartNum(value, obj) {

        },
        openCartList() {
            if (this.cartList.length > 0) {
                this.showPopover = true;
            }
        },
        onPlus() {
            let event = window.event;
            this.createBall(event.x, event.y);
        },
        createBall(left, top) {
            const bar = document.createElement("ball");
            bar.style.position = "fixed";
            bar.style.left = left + "px";
            bar.style.top = top + "px";
            bar.style.width = "20px";
            bar.style.height = "20px";
            bar.style.borderRadius = "50%";
            bar.style.backgroundColor = "red";
            bar.style.zIndex = '999';
            bar.style.transition =
                "left .4s linear, top .4s cubic-bezier(0.5, -0.5, 1, 1)";
            document.body.appendChild(bar);
            setTimeout(() => {
                const x = document.body.clientWidth * 0.5;
                const y = document.body.clientHeight * 1 - 100;
                bar.style.top = '90%';
                bar.style.left = "50px";
            }, 0);
            bar.ontransitionend = function () {
                this.remove();
            };
        },
    }
}
</script>

<style lang="scss" scoped>
.cart-header {
	padding: 18px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}
.cart-body {
	padding: 0 18px;
	.cart-item_info {
		> div {
			padding: 4px 0;
		}
	}
	.cart-item_title {
		font-size: 16px;
		color: $content-color;
	}

	.cart-item_symbol {
		color: #f5b225;
		font-size: 13px;
	}

	.cart-item_text {
		color: #f5b225;
		font-size: 18px;
	}
}

.cart-wrap {
	position: fixed;
	top: 90%;
	width: 100%;
	z-index: 9024;
	.cart {
		margin: 0 15px;
		border-radius: 50px;
		background-color: #fff;
		overflow: hidden;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		height: 50px;
		box-shadow: 0 1px 3px $light-color;
		.cart-info {
			padding: 12px 20px;
			display: flex;
			align-items: center;
			.cart-price {
				margin-left: 15px;
				color: #f5b225;
				.cart-price_symbol {
					font-size: 13px;
				}

				.cart-price_text {
					font-size: 20px;
				}
			}
		}
		.cart-confirm {
			color: #fff;
			font-size: 16px;
			padding: 12px 40px;
			background-color: #f5b225;
		}
	}
}
.header-wrap {
	background-color: #fff2d5;
	padding: 5px 0;
	display: flex;
	justify-content: center;

	/deep/ .count-down {
		color: #ffae00;
		font-weight: 700;
	}
}

.body-wrap {
	.food-wrap {
		padding: 12px;
		padding-top: 0;
		.food-item {
			padding: 16px 0;
			// display: flex;
			// justify-content: space-between;
			&:not(:last-child) {
				border-bottom: 1px solid $border-color;
			}

			.food-name {
				font-size: 15px;
				color: #333;
			}

			.food-desc {
				font-size: 12px;
				color: $second-color;
			}

			.food-price {
				color: #f5b225;
				display: flex;
				flex: 1;
				flex-direction: row;
                justify-content: space-between;
			}

			.food-sold {
				color: $tips-color !important;
				font-size: 12px;
			}

			.food-price_symbol {
				font-size: 13px;
			}

			.food-price_text {
				font-size: 18px;
			}
		}
	}
}

/deep/ .van-stepper.empty {
	.van-stepper__input {
		display: none;
	}
}
</style>
